import React, { useEffect, useState} from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, NavLink } from "react-router-dom";
// import logo from "../../assets/Q_Logo.svg";
import logo from "../../assets/BlueLogoPNG.png";
import "./SidebarMenu.modules.css";
import { AiOutlineUser } from 'react-icons/ai'
import { BiWallet, BiGroup, BiGridAlt } from 'react-icons/bi'
import { MdSettings, MdOutlineReportProblem } from "react-icons/md";
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import {BsGraphUpArrow} from 'react-icons/bs';
import { LuShare } from 'react-icons/lu'
import { GoVerified } from 'react-icons/go';
import { useAuthContextHook } from "../../context/AuthContext";

const SidebarMenu = () => {
  const {alowedRoleData, user} = useAuthContextHook();
  const[userMgmtAllow, setUserMgmtAllow]=useState(false);
  const[lightenningNetwork, setLightenningNetwork]=useState(false);
  const[countryManagement, setCountryManagement]=useState(false);
  const[kycMgmtAllow, setKycMgmtAllow]=useState(false);
  const[currency, setCurrency]=useState(false);
  const[qvaniFee, setQvaniFee]=useState(false);
  const[dispute, setDispute]=useState(false);
  const[feedback, setFeedback]=useState(false);
  const[trade, setTrade]=useState(false);
  const[reward, setReward]=useState(false);
  const[adminCrypto, setAdminCrypto]=useState(false);
  const[adminTransaction, setAdminTransaction] = useState(false);
  const[withdrawalManagement, setWithdrawalManagement]=useState(false);
  const[limit, setLimit]=useState(false);

  useEffect(()=>{
    Object.entries(alowedRoleData).map((data)=>{
      switch(data[0]){
        case "userManagement":
          setUserMgmtAllow(true);
          break;
        
        case "KycManagement":
          setKycMgmtAllow(true);
          break;

        case "lightenningNetwork":
          setLightenningNetwork(true);
          break;
          
        case "countryManagement":
          setCountryManagement(true);
          break;

        case "currency":
          setCurrency(true);
          break;
        
        case "tradePaymentFee":
          setQvaniFee(true);
          break;
        
        case "withdrawalFee":
          setQvaniFee(true);
          break;

        case "internalTransactionFee":
          setQvaniFee(true);
          break;
        
        case "adminfees":
          setQvaniFee(true);
          break;

        case "dispute":
          setDispute(true);
          break;

        case "feedback":
          setFeedback(true);
          break;

        case "trade":
          setTrade(true);
          break;

        case "reward":
          setReward(true);
          break;

        case "adminCrypto":
          setAdminCrypto(true);
          break;
        
        case "adminTransaction":
          setAdminTransaction(true);
          break;

        case "withdrawalManagement":
          setWithdrawalManagement(true);
          break;

        case "limit":
          setLimit(true);
          break;
      }
    })
  },[alowedRoleData])
  return (
    <div className="sticky-top" style={{ display: "flex", height: "100%", zIndex: 10 }}>
      <Sidebar backgroundColor="white" breakPoint="md">
        <Menu>
          <MenuItem
            className="py-3 w-100"
            component={<Link to="/dashboard" />}
            icon={<img className="imageQvaniLogo" src={logo} alt="LOGO" />} >
            {/* <h2 className="fw-bold mb-0">VANI</h2> */}
          </MenuItem>

          {user.role === 'superadmin' && <MenuItem
            component={
              <NavLink activeclassname="active" to="/dashboard" />} icon={<BiGridAlt size={21} />
            }> Dashboard </MenuItem>}

          {userMgmtAllow && <SubMenu icon={<AiOutlineUser size={21} />} label="User Management">
            <MenuItem component={ <NavLink activeclassname="active" to="/usermanagement" /> } >User</MenuItem>
          </SubMenu>}

          {(adminCrypto || adminTransaction || withdrawalManagement) && <SubMenu icon={<BiWallet size={21} />} label="Wallet Management">
            {adminCrypto && <MenuItem component={ <NavLink activeclassname="active" to="/walletmanagement" /> }>Wallet</MenuItem>}
            {adminTransaction && <MenuItem component={ <NavLink activeclassname="active" to="/transactionmanagement" /> }> Transaction </MenuItem>}
            {withdrawalManagement && <MenuItem component={ <NavLink activeclassname="active" to="/withdrawalmanagement" /> }> Withdrawal</MenuItem>}
          </SubMenu>}

          {(qvaniFee || lightenningNetwork) && <SubMenu icon={<FaRegMoneyBillAlt size={21} />} label="Fee Management">
            {qvaniFee && <MenuItem component={<NavLink activeclassname="active" to="/feemanagement" />} >Qvani Fee</MenuItem>}
            {lightenningNetwork && <MenuItem component={<NavLink activeclassname="active" to="/conversion" />} > Conversion Fee </MenuItem>}
          </SubMenu>}

          {(kycMgmtAllow || limit) && <SubMenu icon={<GoVerified size={21} />} label='KYC Management'>
            {kycMgmtAllow && <MenuItem component={<NavLink activeclassname="active" to="/kycmanagement" />} > KYC </MenuItem>}
            {limit && <MenuItem component={<NavLink activeclassname="active" to="/limits" />} >Limits</MenuItem>}
          </SubMenu>}

          {(trade || feedback) && <SubMenu icon={<BsGraphUpArrow size={21}/>} label="Trade Management">
            {trade && <MenuItem component={ <NavLink activeclassname="active" to="/activetrademanagement" />} > Active Trades </MenuItem>}
            {feedback && <MenuItem component={<NavLink activeclassname="active" to="/feedbackmanagement" />} > Feedback </MenuItem>}
          </SubMenu>}

          {dispute && <MenuItem
            component={
              <NavLink activeclassname="active" to="/disputemanagement" />
            } icon={<MdOutlineReportProblem size={21} />}> Dispute Management</MenuItem>}

          {user.role === 'superadmin' &&<SubMenu icon={<BiGroup size={21} />} label="Role Management">
            <MenuItem component={<NavLink activeclassname="active" to="/rolemanagement" />} > Roles </MenuItem>
             <MenuItem component={ <NavLink activeclassname="active" to="/roles-user" />}> Role-Users </MenuItem>
          </SubMenu>}

          {reward && <MenuItem
            component={
              <NavLink activeclassname="active" to="/referralmanagement" />
            } icon={<LuShare size={21} />} >Referral management </MenuItem>}

          {(currency || countryManagement) && <SubMenu icon={<MdSettings size={21} />} label='Other Settings'>
            {currency && <MenuItem component={<NavLink activeclassname="active" to="/currencymanagement"/>}>Currency</MenuItem>}
            {countryManagement && <MenuItem component={<NavLink activeclassname="active" to="/countrymanagement"/>}>Country </MenuItem>}
            <MenuItem component={<NavLink activeclassname="active" to="/blacklistmanagement" />} > BlackList </MenuItem>
          </SubMenu>}
          
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarMenu;
