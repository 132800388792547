const envoirnment = {
  development: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_URL1: process.env.REACT_APP_BASE_URL1,
    BASE_URL2: process.env.REACT_APP_BASE_URL2,
    ADMIN_BASE: process.env.REACT_APP_BASE_URL3,
  },
  staging: {
    BASE_URL: process.env.REACT_APP_STAGE_BASE_URL,
    BASE_URL1: process.env.REACT_APP_STAGE_BASE_URL1,
    BASE_URL2: process.env.REACT_APP_STAGE_BASE_URL2,
    ADMIN_BASE: process.env.REACT_APP_STAGE_BASE_URL3,
  }, 
  production: {
    BASE_URL: process.env.REACT_APP_PROD_BASE_URL,
    BASE_URL1: process.env.REACT_APP_PROD_BASE_URL1,
    BASE_URL2: process.env.REACT_APP_PROD_BASE_URL2,
    ADMIN_BASE: process.env.REACT_APP_PROD_BASE_URL3,
  }
}
const apiBaseUrl = envoirnment[process.env.REACT_APP_API_ENVIROMENT];

export const API_URLs = {

  logIn: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/login`,
  loginVerify: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/login-verify`,
  forgetPassword: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/forgot-password`,
  reset: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/reset-password`,
  logout: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/logout`,
  adminActivity: (id) => `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/activity/login-history/${id}`,

  getAdminDashboard: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getCoutuserandcurrency`,



  getUser: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/users`,
  getSingleUser: (userId) => `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/user/${userId}`,
  userWallet: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getuserwallets`,
  getSingleUserWallet: (userId) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getuserwallets/${userId}`,
  getSingleUserParticularWallet: (user, id, status) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getuserwallets/${user}/${id}/${status}`,
  getSingleUserTrade: (userId) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/getUserTrades/${userId}`,
  blockUser: (userId, status) => {
    return `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/user/${userId}/${status}`
  },
  getBlockUserList: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/getAllBlockedUsers`,
  getUserPublicProfile: (userId) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/publicapi/userPublicProfile/${userId}`,
  getUserActivity: (id)=> `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/login-history/${id}`,

  getProfile: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/auth/profile`,

  getCryptoWallet: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getWallets`,
  getAllWallet: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getUserWalletsBalance`,
  getBalance: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getBalance`,

  blockCrypto: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/blockCrypto`,
  unBlockCrypto: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/unBlockCrypto`,



  cryptoTransaction: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getuserstransaction`,
  cryptoTransactionId: (id) =>
    `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminCrypto/getuserstransaction/${id}`,

  fiatTransaction: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminfiatwallet/transactions`,
  fiatTransactionStatus: (id, status) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminfiatwallet/transactions/${id}/${status}`,

  postFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/`,
  getFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/`,
  getFeeId: (id) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/fee/${id}`,
  deleteGetFeeId: (id) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/fee/${id}`,
  updateFee: (id) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/fee/${id}`,

  getDispute: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/admindispute/`,
  getDisputeId: (id) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/admindispute/${id}`,
  solveDispute: (id, status = "solve") => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/admindispute/${id}/${status}`,

  tradeDispute: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTradeDispute/`,
  tradeDisputeById: (id) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTradeDispute/${id}`,
  tradeDisputeResolve: (id) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTradeDispute/${id}/solve`,


  kycList: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/kyc/users`,


  getCurrency: `${apiBaseUrl.BASE_URL}/user-service-api/v1/public-users/all-currency`,
  getCurrencyId: (id) =>
    `${apiBaseUrl.BASE_URL}/user-service-api/v1/public-users/all-currency/${id}`,


  getCountry: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/getAllCountriesAsAdmin`,
  getCountryId: (id) =>
    `${apiBaseUrl.BASE_URL}/user-service-api/v1/public-users/all-country/${id}`,


  getAllUserId: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTrustedUser/getAllUsers`,
  trustUntrust: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTrustedUser/getAllTrustedUntrustedUsers`,
  userSuspendment : `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/userSuspendment` ,



  getTrade: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/trade`,
  getTradeId: (id) =>
    `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/tradeById/${id}`,

  changePassword: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/changePassword`,


  feedback: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/getUserFeedback`,
  deletefeedback: (id) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/deleteFeedback/${id}`,


  limitLevel: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/limit`,
  updateLimit: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/limit`,

  active: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminOffers/activeTrades`,
  getChat: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/userchat/getChatByAdmin`,
  addProof: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/userchat/admin-add-proof`,

  getAllSuspendUser : `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/getAllSuspendedUser`,


  blockCountry: (id) => `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/countryBlock/${id}`,
  getBlockedCountry: `${apiBaseUrl.BASE_URL}/user-service-api/v1/admin/BlockCountries`,
  adminAddChat: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/userchat/addChatforAdmin`,


  getWithdrawalAmount: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/withdrawlAmount`,
  editWithdrawalAmount: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/withdrawlAmount`,
  getWithdrawalList: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/withdrawl/getWithdrawlRequestList`,
  approved: (id) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/withdrawl/withdrawlRequestStatusChange/${id}`,


  estGasFeeForAdmin: (currency,amount,receiverAddress) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/withdrawl/estGafeeForAdmin/${currency}/${amount}/${receiverAddress}`,
  withdrawlByAdmin : `${apiBaseUrl.BASE_URL1}/wallet-service/v1/withdrawl/withdrawlByAdmin`,


  allApplicant: `${apiBaseUrl.BASE_URL}/user-service-api/v1/sumsub/getAllSumsubApplicants`,


  internalFeeList: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/internalTrnsaferFee`,

  externalFeeList: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/withdrawlFee`,

  paymentMethodFeeList: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/adminFee/tradePaymentFee`,
  paymentMethodStatus: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/offers/paymentMethods`,
  disablePaymentOption: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/offers/paymentMethodsOption`,

  disputeDays: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/disputeRaiseDays/get`,
  setDisputeDays: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/disputeRaiseDays/set`,
  releaseEscrow: (id, option) => `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/adminTradeDispute/sendDisputeCrypto/${id}/${option}`,

  convertcryptoToFiat: (from, amount, to) => `${apiBaseUrl.BASE_URL1}/wallet-service/v1/conversion?from=${from}&amount=${amount}&target=${to}`,


  minTradeLim: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/offers/minOfferAmounts`,
  minTradeLimitGet: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/offers/minOfferAmountsForAdmin`,

  getSwapFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/convertCurrency/getSwapFee`,
  updateSwapFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/convertCurrency/UpdateSwapFee`,

  getLightFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/lightiningNetwork/getlightningFee`,
  updateLightFee: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/lightiningNetwork/lightningFeeUpdate`,
  invoiceList: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/lightiningNetwork/invoiceList`,
  getAllInvoice: `${apiBaseUrl.BASE_URL1}/wallet-service/v1/lightiningNetwork/invoiceUserList`,


  rewardDetails: `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/reward/rewardDetails`,
  rewardAmountManagement : `${apiBaseUrl.BASE_URL2}/offer-service-api/v1/reward/rewardAmountManagement`,


  addRole : `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/role`,
  getRole : `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/role`,
  deleteRoleManagement: (id) => `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/role/${id}`,
  getSingleRoleManagement: (id) => `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/role/${id}`,
  getAllPermission: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/permissions`,
  getSingleRolePermission: (id)=>`${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/permission-with-role-permission/${id}`,
  getGivePermission: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/role/grant-many-permissions`,
  revokeSinglePermission: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/revoke-permission`,
  revokeAllPermission: (id) => `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/revoke-allpermission/${id}`,


  createUserRole: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/users`,
  assignRole: `${apiBaseUrl.ADMIN_BASE}/v1/admin-service/rolePermission/roleAssign`
};
